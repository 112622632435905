var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('header', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": 'Jurnal'
    }
  }, [_c('b-row', [_c('b-col', [_c('validation-observer', {
    ref: "formakun"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Transaksi"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'Debit'
      }, {
        value: 2,
        text: 'Kredit'
      }],
      "id": "v-transaction_type",
      "name": "transaction_type"
    },
    model: {
      value: _vm.transactionType,
      callback: function callback($$v) {
        _vm.transactionType = $$v;
      },
      expression: "transactionType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }), _vm.transactionType ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Akun"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_akun"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_akun,
            "id": "v-id_akun",
            "name": "id_akun"
          },
          model: {
            value: _vm.form.id_akun,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "id_akun", $$v);
            },
            expression: "form.id_akun"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3094551074)
  })], 1)], 1) : _vm._e(), _vm.transactionType ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_kas"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_kas,
            "id": "v-id_kas",
            "name": "id_kas"
          },
          model: {
            value: _vm.form.id_kas,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "id_kas", $$v);
            },
            expression: "form.id_kas"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 247312770)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu",
      "label-for": "v-tanggal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tanggal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              enableTime: true,
              dateFormat: 'Y-m-d'
            }
          },
          model: {
            value: _vm.form.tanggal,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tanggal", $$v);
            },
            expression: "form.tanggal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "v-keterangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "kterangan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "v-keterangan",
            "placeholder": "Isi keterangan"
          },
          model: {
            value: _vm.form.keterangan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "keterangan", $$v);
            },
            expression: "form.keterangan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.isDebitAkun ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Debit",
      "label-for": "v-debit"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "debit"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-debit",
            "placeholder": "Isi "
          },
          on: {
            "keyup": function keyup($event) {
              _vm.form.debit = _vm.formatRupiah(_vm.form.debit);
            }
          },
          model: {
            value: _vm.form.debit,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "debit", $$v);
            },
            expression: "form.debit"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4261100452)
  })], 1)], 1) : _vm._e(), _vm.isKreditAkun ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kredit",
      "label-for": "v-kredit"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "kredit"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-kredit",
            "placeholder": "Isi Kredit"
          },
          on: {
            "keyup": function keyup($event) {
              _vm.form.kredit = _vm.formatRupiah(_vm.form.kredit);
            }
          },
          model: {
            value: _vm.form.kredit,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kredit", $$v);
            },
            expression: "form.kredit"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 733696993)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Tambah ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }